'use client';

import styled from 'styled-components';

import { Container, Layout } from '@/components/core';
import { Intro } from '../shared';

import ErrorSVG from '@/public/assets/icons/page-error.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 168px; // 80px navbar + 88px
  padding-bottom: 144px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 148px; // 60px navbar + 88px
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 124px; // 60px navbar + 64px
    padding-bottom: 64px;
  }
`;

const ImageWrapper = styled(ErrorSVG)`
  height: 44vh;
  max-height: 389px;
  margin-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 22vh;
  }
`;

function ErrorPage({ title, subtitle, buttonLabel, buttonProps }) {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <ImageWrapper />

          <Intro
            title={title}
            titleAs="h1"
            description={subtitle}
            size="sm"
            textSize="md"
            button={{
              ...buttonProps,
              label: buttonLabel,
              size: 'sm',
              variant: 'tertiary',
            }}
            xs="0"
            sm="0"
            md="0"
          />
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default ErrorPage;
